/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-17 18:00:30
 * @LastEditTime: 2024-09-12 16:24:04
 */
import { createApp } from 'vue'
import '@/assets/style/base.css'
import 'normalize.css';
import App from '@/App.vue'
// 国际化i18n
import { VueI18n } from '@/tools/i18n'
// pinia全局状态管理
import { pinia } from '@/tools/pinia/index'
// pinia持久化
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
// 路由
import router from "@/router/index"
// 图片懒加载指令
import lazy from '@/utils/lazy.js'
// 权限指令
import limit from '@/utils/limit.js'
// 防抖
import debounce from '@/utils/debounce.js'
// 溢出省略号提示指令
import Tooltip from '@/utils/Tooltip.js'
// element-plus组件库
import Element from 'element-plus'
// main.js:注册所有图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 图标带悬浮提示组件
import tipIcom from '@/components/common/tipIcom.vue';


// 日期选择组件
const app = createApp(App)
// 注册全局组件
app.component('tipIcom', tipIcom);

app.use(Element, {
    // https://github.com/iview/iview/issues/1090
    i18n: (path, option) => {
        let value = i18n.t(path, option)
        if (value !== null && value !== undefined) {
            return value;
        }
        return ''
    }
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
pinia.use(piniaPluginPersistedstate);
app.use(pinia)
app.use(VueI18n)
app.use(router)
app.use(lazy)
app.use(limit)
app.use(debounce)
app.use(Tooltip)
app.mount('#app')
