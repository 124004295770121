export default {
    install(Vue) {
        Vue.directive('debounce', {
            // 指令挂载时
            mounted(el, binding) {
                const { handler, delay = 300 } = binding.value || {};
                let timeout;

                // 防抖处理函数
                const debounceHandler = (event) => {
                    if (binding.modifiers.enter && event.key !== 'Enter') {
                        return; // 如果指定了 enter 修饰符，但按下的不是 Enter 键，则忽略
                    }
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        handler(event);
                    }, delay);
                };

                // 确定事件类型
                const eventType = binding.arg || 'click';

                // 添加事件监听
                el.addEventListener(eventType, debounceHandler);

                // 清理函数
                el._cleanup = () => {
                    el.removeEventListener(eventType, debounceHandler);
                    clearTimeout(timeout);
                };
            },
            // 指令卸载时
            unmounted(el) {
                if (el._cleanup) {
                    el._cleanup();
                }
            }
        });
    },
};
