/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-01-09 10:15:22
 * @LastEditTime: 2024-06-14 18:14:15
 */
export default {
    install(Vue) {
        Vue.directive('lazy', {
            update(el, binding) {
                let timeoutId;
                let observer = new IntersectionObserver((entries) => {
                    // 使用防抖
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => {
                        if (entries[0].isIntersecting) {
                            // 需要使用nexttick，不然获取元素上绑定的data-src值
                            if (binding.value) {
                                Vue.nextTick(() => {
                                    // console.log(el, binding.value, 'binding.value');
                                    el.src = binding.value;
                                });
                            }
                            observer.unobserve(el);
                        }
                    }, 100);
                }, { threshold: 0.01 });
                observer.observe(el);
                // 可选：在组件销毁时取消观察器
                // el.$on('hook:destroyed', () => {
                //     observer.disconnect();
                // });
            },
        });
    },
};

